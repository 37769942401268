import { MdReportGmailerrorred } from "react-icons/md";

export const userAccess = [
  {
    email: "jkjitendra4292@gmail.com",
    userType: "admin",
    designation: "admin",
  },

  {
    email: "kumar.adarshavi99@gmail.com",
    userType: "admin",
    designation: "admin",
  },

  {
    email: "kuamarap1116@gmail.com",
    userType: "admin",
    designation: "admin",
  },
];
