export const advertisements = [
  {
    adNo: "1",
    width: "384px",
    height: "288px",
    page: "Home Page",
  },

  {
    adNo: "2",
    width: "728px",
    height: "90px",
    page: "Home Page",
  },

  {
    adNo: "3",
    width: "728px",
    height: "90px",
    page: "Home Page",
  },
];
